import React, { useState, useEffect } from 'react';
import s from './Modal.module.scss';
import classNames from 'classnames';

const Modal = ({
    title = '',
    description = '',
    aboutText = '',
    contactText = '',
    products = {},
    activeTab = '',
    setActiveTab = () => {},
    setModalOpen = () => {},
    isModalOpen = false,
}) => {
    const categories = Object.keys(products).filter(category => products[category].items.length);
    const hasAIModels = categories.length > 0;

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add("no-scroll");
            document.getElementById('modal').focus();
        } else {
            document.body.classList.remove("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [isModalOpen]);

    const handleStopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div 
            className={s['Modal']} 
            onClick={() => setModalOpen(false)}
        >
            <div 
                className={s['Modal__Inner']} 
                onClick={handleStopPropagation}
            >
                <button 
                    className={s['Modal__Close']} 
                    onClick={() => setModalOpen(false)}
                    type="button"
                    id={"modal"}
                ></button>
                <div className={s['Modal__Header']}>
                    <h2 className={s['Modal__Title']}>{title}</h2>

                    <div
                        className={s['Modal__Desc']}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    { hasAIModels &&
                        <div className={s['Modal__Tabs']}>
                            <button
                                className={classNames(
                                    s['Modal__Tab'],
                                    {[s['Modal__Tab--Active']]: activeTab === 0}
                                )}
                                onClick={() => setActiveTab(0)}
                            >AI-INNEHÅLLS&shy;FÖRTECKNING</button>
                            <button
                                className={classNames(
                                    s['Modal__Tab'],
                                    {[s['Modal__Tab--Active']]: activeTab === 1}
                                )}
                                onClick={() => setActiveTab(1)}
                            >OM AI</button>
                        </div>
                    }
                    <div className={s['Modal__Content']}>
                        {activeTab === 0 && hasAIModels &&
                            <Products
                                products={products}
                                categories={categories}
                            />
                        }
                        {activeTab === 1 &&
                            <div
                                className={classNames(
                                    s['Modal__AboutText'],
                                    {[s['Modal__AboutText--FullHeight']]: !hasAIModels}
                                )}
                                dangerouslySetInnerHTML={{ __html: aboutText }}
                            />
                        }
                    </div>
                    {contactText &&
                        <div
                            className={s['Modal__Contact']}
                            dangerouslySetInnerHTML={{ __html: contactText }}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {};


const Products = ({ 
    products = {},
    categories = [],
}) => {    
    const [activeCategory, setActiveCategory] = useState(categories[0]);

    const hasOnlyContentCategory = categories.length === 1 && categories[0] === 'content';
    
    const currentCategory = products[activeCategory] && products[activeCategory].items.length 
        ? products[activeCategory].items
        : null;

    const classes = classNames(
        s['Modal__Products'],
        {[s['Modal__Products--HideTabs']]: hasOnlyContentCategory },
    );

    return (
        <div className={classes}>
            <div className={s['Modal__Categories']}>
                {categories.map((category, index) => (
                    <button 
                        key={index}
                        className={classNames(
                            s['Modal__Category'],
                            {[s['Modal__Category--Active']]: category === activeCategory}
                        )}
                        onClick={() => setActiveCategory(category)}
                        type="button"
                    >
                        {products[category].title}
                    </button>
                ))}
            </div>
            
            <div className={s['Modal__TableWrapper']}>
                <table className={s['Modal__Table']}>
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Leverantör</th>
                            <th>Beskrivning</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCategory && currentCategory.map((item, index) => (
                            <tr key={index} className={classNames(
                                s['Modal__TableRow'],
                                {[s['Modal__TableRow--White']]: index % 2 === 0}
                            )}>
                                <td>{item.name}</td>
                                <td>
                                    <div className={classNames(
                                        s['Modal__ItemProviderWrapper'],
                                        {[s['Modal__ItemProviderWrapper--NoUrl']]: !item.providerUrl },
                                        {[s['Modal__ItemProviderWrapper--HasImage']]: item.providerLogo && item.providerLogo.src }
                                    )}>
                                        {item.providerLogo && item.providerLogo.src
                                            ? <img 
                                                className={s['Modal__ItemIcon']}
                                                src={item.providerLogo.src} 
                                                alt={item.provider} 
                                            />
                                            : <span className={s['Modal__ItemIconText']}>
                                                {item.provider}
                                            </span>
                                        }
                                        {item.providerUrl &&
                                            <a
                                                href={item.providerUrl}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                title={item.provider}
                                                className={s['Modal__ItemProvider']}
                                            ></a>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <p className={s['Modal__ItemDescription']}>
                                        {item.description}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>   
            </div>
        </div>
    );
}

Products.propTypes = {};

export default Modal;